<template>
  <div class="options-panel">
    <div class="header">Options</div>
    <div class="content">
      <!-- Type -->
      <div class="form-group">
        <label class="col-form-label"> Type</label>
        <select class="input form-control" v-model="options.type">
          <option value="Cardigan">Cardigan</option>
          <option value="Jumper">Jumper</option>
          <option value="Trouser">Trouser</option>
          <option value="Skirt">Skirt</option>
          <option value="Shorts">Shorts</option>
          <option value="Winter T-Shirt">Winter T-Shirt</option>
          <option value="Summer T-Shirt">Summer T-Shirt</option>
          <option value="House T-Shirt">House T-Shirt</option>
          <option value="Sport Jacket">Sport Jacket</option>
          <option value="Sport Pants">Sport Pants</option>
          <option value="Sport Shorts">Sport Shorts</option>
        </select>
      </div>

      <!-- Size -->
      <div class="form-group">
        <label class="col-form-label"> Size</label>
        <select class="input form-control" v-model="options.size">
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
        </select>
      </div>

      <!-- Price -->
      <div class="form-group">
        <label class="col-form-label">Price</label>
        <input
          class="input form-control"
          type="number"
          placeholder="Ron"
          v-model="options.price"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["options"]
};
</script>

<style>
</style>
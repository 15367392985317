<template>
  <div class="avatar">
    <div class="change-image" @click="toggleShow">
      <span>{Change Image}</span>
    </div>

    <my-upload
      field="img"
      langType="en"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      :width="100"
      :height="100"
      url=""
      :params="params"
      :headers="headers"
      img-format="png"
    ></my-upload>
    <img class="uploaded-image" :src="avatarImg" />
  </div>
</template>

<script>
import "babel-polyfill"; // es6 shim
import myUpload from "vue-image-crop-upload";
import { ref, watch } from "@vue/reactivity";

export default {
  props: ["img"],

  setup(props) {
    const show = ref(false);
    const params = ref({
      token: "123456798",
      name: "avatar",
    });
    console.log("Image Upload ", props);
    const headers = ref({ smail: "*_~" });

    const avatarImg = ref(props.img);

    const toggleShow = () => {
      show.value = !show.value;
    };
    const cropSuccess = (imgDataUrl, field) => {
      console.log("-------- crop success --------");
      avatarImg.value = imgDataUrl;
      props.img = imgDataUrl;
    };
    const cropUploadSuccess = (jsonData, field) => {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    };

    const cropUploadFail = (status, field) => {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    };
    return {
      show,
      params,
      headers,
      avatarImg,
      toggleShow,
      cropSuccess,
      cropUploadSuccess,
      cropUploadFail,
    };
  },

  watch: {
    img(newavatar, oldAvatar) {
      this.avatarImg = newavatar;
    },
  },
  components: {
    "my-upload": myUpload,
  },
};
</script>

<style lang="scss">
@import "@/colors.scss";
.avatar {
  width: 25.5mm;
  height: 25.5mm;
  border-radius: 50%;
  overflow: hidden;

  position: absolute;
  margin-left: 8.5mm;
  box-shadow: $shadow;
  border: 4px #fff solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    .change-image {
      display: flex;
    }
  }
  .change-image {
    display: none;
    position: absolute;
    width: 25.5mm;
    height: 25.5mm;
    top: 0;
    left: 0;

    align-items: center;
    justify-content: center;
    font-family: "Varela";
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.45);
  }
  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>

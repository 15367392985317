import { createRouter, createWebHistory } from 'vue-router'
import { dbAuth } from '../firebase/config';
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import EmployeeCardsPage from "@/components/EmployeeCards/EmployeeCardsPage.vue";
import SubjectCardsPage from "@/components/SubjectCards/SubjectCardsPage.vue";
import ClothesStickersPage from "@/components/ClothesStickers/ClothesStickersPage.vue";
import HousePointsPage from "@/components/HousePoints/HousePointsPage.vue";


// Auth Guard: if the user is not logged in then redirect him to the login page
// another requried piece of code is at main.js
const requireAuth = (to, from, next) => {
  let user = dbAuth.currentUser;
  if (!user) {
    next({ name: 'Login' });
  }
  else {
    next();
  }
}

// if the user is logged in and goes to login, then redirect him to home.
const requireNoAuth = (to, from, next) => {
  let user = dbAuth.currentUser;
  if (user) {
    next({ path: 'Home' });
  }
  else {
    next();
  }
}


const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '',
        name: 'EmployeeCardsPage',
        component: EmployeeCardsPage,
        beforeEnter: requireAuth
      },
      {
        path: '/SubjectCards',
        name: 'SubjectCardsPage',
        component: SubjectCardsPage,
        beforeEnter: requireAuth
      },
      {
        path: '/ClothesStickers',
        name: 'ClothesStickersPage',
        component: ClothesStickersPage,
        beforeEnter: requireAuth
      }
      ,
      {
        path: '/HousePoints',
        name: 'HousePointsPage',
        component: HousePointsPage,
        beforeEnter: requireAuth
      }

    ]
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div class="subject-cards-page">
    <SubjectCardsOptions :options="options" />

    <transition-group tag="div" name="list" appear class="page-a4">
   
   
      <!-- I used the n + 100 for the key so each group can have a unique key -->

      <!-- Blank -->
      <SubjectCard
        v-for="n in options.blankCount"
        :key="n + 200"
        subjectCSS="subject-blank"
      />

      <!-- Math -->
      <SubjectCard
        v-for="n in options.mathCount"
        :key="n + 100"
        subjectCSS="subject-math"
        :studentClass="options.studentClass"
        subject="Math"
        :studentName="options.studentName"
      />

      <!-- History -->
      <SubjectCard
        v-for="n in options.historyCount"
        :key="n + 200"
        subjectCSS="subject-history"
        :studentClass="options.studentClass"
        subject="History"
        :studentName="options.studentName"
      />

      <!-- English -->
      <SubjectCard
        v-for="n in options.englishCount"
        :key="n + 300"
        subjectCSS="subject-english"
        :studentClass="options.studentClass"
        subject="English"
        :studentName="options.studentName"
      />

      <!-- Geography -->
      <SubjectCard
        v-for="n in options.geographyCount"
        :key="n + 400"
        subjectCSS="subject-geography"
        :studentClass="options.studentClass"
        subject="Geography"
        :studentName="options.studentName"
      />

      <!-- Science  -->
      <SubjectCard
        v-for="n in options.scienceCount"
        :key="n + 500"
        subjectCSS="subject-science"
        :studentClass="options.studentClass"
        subject="Science"
        :studentName="options.studentName"
      />

      <!-- P.S.H.E  -->
      <SubjectCard
        v-for="n in options.psheCount"
        :key="n + 600"
        subjectCSS="subject-pshe"
        :studentClass="options.studentClass"
        :studentName="options.studentName"
      >
        <span>P.</span> <span>S.</span> <span>H.</span> <span>E.</span>
      </SubjectCard>

      <!-- Topic  -->
      <SubjectCard
        v-for="n in options.topicCount"
        :key="n + 600"
        subjectCSS="subject-topic"
        :studentClass="options.studentClass"
        subject="Topic"
        :studentName="options.studentName"
      />

      <!-- Spelling  -->
      <SubjectCard
        v-for="n in options.spellingCount"
        :key="n + 600"
        subjectCSS="subject-spelling"
        :studentClass="options.studentClass"
        subject="Spelling"
        :studentName="options.studentName"
      />

      <!-- Journal  -->
      <SubjectCard
        v-for="n in options.journalCount"
        :key="n + 700"
        subjectCSS="subject-journal"
        :studentClass="options.studentClass"
        subject="Journal"
        :studentName="options.studentName"
      />

      <!-- German  -->
      <SubjectCard
        v-for="n in options.germanCount"
        :key="n + 800"
        subjectCSS="subject-german"
        :studentClass="options.studentClass"
        subject="German"
        :studentName="options.studentName"
      />

      <!-- Romanian  -->
      <SubjectCard
        v-for="n in options.romanianCount"
        :key="n + 900"
        subjectCSS="subject-romanian"
        :studentClass="options.studentClass"
        subject="Romanian"
        :studentName="options.studentName"
      />

      <!-- Default  -->
      <SubjectCard
        v-for="n in options.defaultCount"
        :key="n + 1000"
        subjectCSS="subject-default"
        :studentClass="options.studentClass"
        subject="Blank"
        :studentName="options.studentName"
      />

      <!-- Christmas  -->
      <SubjectCard
        v-for="n in options.christmasCount"
        :key="n + 1000"
        subjectCSS="subject-christmas"
        :studentClass="options.studentClass"
        subject="Christmas"
        studentName="Teacher Name"
      />
      <!-- Whiteboard  -->
      <SubjectCard
        v-for="n in options.whiteboardCount"
        :key="n + 1000"
        subjectCSS="subject-whiteboard"
        :studentClass="options.studentClass"
      />
    </transition-group>

    <div class="fab-container">
      <div
        class="fab-btn neutral-btn custom-tooltip tooltip-left"
        @click="printPage"
      >
        <span class="tooltip-name">Print</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M22,8H17V5a1,1,0,0,0-.293-.707L13.72,1.306c0-.005-.006-.011-.01-.016a.867.867,0,0,0-.163-.1A.983.983,0,0,0,13,1H8A1,1,0,0,0,7,2V8H2A1,1,0,0,0,1,9V19a1,1,0,0,0,1,1H7v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V20h5a1,1,0,0,0,1-1V9A1,1,0,0,0,22,8ZM9,3h3V5a1,1,0,0,0,1,1h2V8H9Zm6,18H9V17h6Zm6-3H17V16a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v2H3V10H21Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from "@vue/reactivity";
import SubjectCardsOptions from "@/components/SubjectCards/SubjectCardsOptions.vue";
import SubjectCard from "@/components/SubjectCards/SubjectCard.vue";

export default {
  components: {
    SubjectCardsOptions,
    SubjectCard,
  },

  setup() {
    const options = ref({
      studentName: null,
      studentClass: "Year 6",
      blankCount: 0,
      mathCount: 1,
      historyCount: 1,
      englishCount: 1,
      geographyCount: 1,
      scienceCount: 1,
      psheCount: 1,
      topicCount: 1,
      spellingCount: 1,
      journalCount: 1,
      germanCount: 1,
      romanianCount: 1,
      defaultCount: 1,
      christmasCount: 1,
      whiteboardCount: 1,
    });

    function printPage() {
      window.print();
    }

    // function randomKey() {
    //   return Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
    // }

    return {
      options,
      printPage,
    };
  },
};
</script>

<style>
</style>
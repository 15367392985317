import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Important to have a working authentication guard
import { dbAuth } from './firebase/config';

let app;
dbAuth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(router).mount('#app')
    }
})



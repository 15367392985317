<template>
  <div class="login-page">
    <div class="login-container">
      <img src="../assets/img/logo.svg " alt="" class="logo" />
      <form class="login-form" @submit.prevent="handleSubmit">
        <div class="form-group"   :class="error ? 'input-error' : null">
          <label class="col-form-label"> Email</label>
          <input
            class="input form-control"
            type="email"
            placeholder="Enter email address"
            required
            v-model="email"
          
          />
        </div>
        <div class="form-group"  :class="error ? 'input-error' : null">
          <label class="col-form-label"> Password</label>
          <input
            class="input form-control"
            type="password"
            placeholder="Enter password"
            required
            v-model="password"
          />
        </div>

        <div :class="error ? 'login-error' : null">{{error}}</div>

        <button class="btn primary-btn">Login</button>

        <span class="rights"
          >Created by
          <a href="https://www.behance.net/remyjouni" target="_blank"
            >Remy Jouni</a
          ></span
        >
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useLogin from "../composables/useLogin";
export default {
  setup() {
    const router = useRouter();
    const email = ref(null);
    const password = ref(null);

    const { error, login } = useLogin(); //destructing the login function

    async function handleSubmit() {
      await login(email.value, password.value); // passing the login credentials
      if (!error.value) {
        router.push({ path: "Home" }); // if I used name: 'home' then the data won't load from the default component
      }

     
    }

    return { email, password, error, handleSubmit };
  },
};
</script>

<style>
</style>
<template>
  <transition-group tag="div" name="list" appear class="cards-container">
    <div
      class="employee-card"
      v-for="(card, index) in employeesCards"
      :key="card.id"
    >
      <!-- :key="JSON.stringify(card)" -->
      <div class="front-face">
        <div class="ellipse1"></div>
        <div class="ellipse2"></div>
        <div class="ellipse3"></div>
        <div class="ellipse4"></div>

        <ImageUploader :img="defaultAvatar" />

        <div class="employee-data">
          <span
            class="title"
            role="textbox"
            contenteditable
            @blur="updateCard(card, $event, 'title')"
            >{{ card.title }}</span
          >
          <span
            class="name"
            role="textbox"
            contenteditable
            @blur="updateCard(card, $event, 'name')"
            >{{ card.name }}</span
          >
          <span
            class="position"
            role="textbox"
            contenteditable
            @blur="updateCard(card, $event, 'position')"
          >
            {{ card.position }}
          </span>
        </div>
      </div>
      <div class="back-face">
        <div class="ellipse1"></div>
        <div class="ellipse2"></div>
        <div class="ellipse3"></div>
        <img class="logo" src="@/assets/img/MISB-Logo.svg" />
      </div>

      <div
        class="fab-btn neutral-btn delete-btn custom-tooltip tooltip-right"
        @click="removeCard(index)"
      >
        <span class="tooltip-name">Remove</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M4.293,18.293,10.586,12,4.293,5.707A1,1,0,0,1,5.707,4.293L12,10.586l6.293-6.293a1,1,0,1,1,1.414,1.414L13.414,12l6.293,6.293a1,1,0,1,1-1.414,1.414L12,13.414,5.707,19.707a1,1,0,0,1-1.414-1.414Z"
          />
        </svg>
      </div>
    </div>
  </transition-group>
  <!-- <button @click="printArray">print</button> -->
</template>

<script>
import { ref } from "@vue/reactivity";
import ImageUploader from "@/components/EmployeeCards/ImageUploader.vue";
import femaleAvatar from "@/assets/img/default-avatar.svg";
import maleAvatar from "@/assets/img/male-avatar.svg";
export default {
  setup() {
    const defaultAvatar = ref(femaleAvatar);
    const updateCard = (card, event, type) => {
      if (type === "title") {
        card.title = event.target.innerText;
        if (card.title != "Ms.") {
          defaultAvatar.value = maleAvatar;
        }
        if (card.title === "Ms.") {
          defaultAvatar.value = femaleAvatar;
        }
      } else if (type === "name") {
        card.name = event.target.innerText;
      } else if (type === "position") {
        card.position = event.target.innerText;
        ("vue");
      }
    };
    return { defaultAvatar, updateCard };
  },

  props: ["employeesCards"],

  components: {
    ImageUploader,
  },

  methods: {
    removeCard(index) {
      // console.log(card);
      console.log(this.employeesCards);
      console.log(index);

      // console.log(this.employeesCards[card]);
      console.log(this.employeesCards[index]);
      this.employeesCards.splice(index, 1);
    },

    printArray() {
      console.log(this.employeesCards);
    },
  },
};
</script>

<style></style>

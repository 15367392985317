<template>

<div class="container scrollbar">
  <nav class="top-nav">
    <img class="logo" src="@/assets/img/logo.svg" />
    <router-link class="nav-link" :to="{ name: 'EmployeeCardsPage' }"> Employees </router-link>
    <router-link class="nav-link" :to="{ name: 'SubjectCardsPage' }"> Subjects</router-link>
    <router-link class="nav-link" :to="{ name: 'HousePointsPage' }"> House Points</router-link>
    <router-link class="nav-link" :to="{ name: 'ClothesStickersPage' }"> Uniform</router-link>
  </nav>

  <router-view />
</div>
 
</template>

<script>
// @ is an alias to /src
import EmployeeCardsPage from "@/components/EmployeeCards/EmployeeCardsPage.vue";
import SubjectCardsPage from "@/components/SubjectCards/SubjectCardsPage.vue";
import ClothesStickersPage from "@/components/ClothesStickers/ClothesStickersPage.vue";
import HousePointsPage from "@/components/HousePoints/HousePointsPage.vue";

export default {
  components: {
    EmployeeCardsPage,
    SubjectCardsPage,
    ClothesStickersPage,
    HousePointsPage
  },
};
</script>

<style  >
</style>
<template>
  <div class="house-points-page">
    <HousePointsOptions :options="options" />

    <transition-group tag="div" name="list" appear mode="out-in" class="page-a4">
      <!-- Dragons  -->
      <HouseCard
        :options="options"
        v-for="n in options.dragonsCount"
        :key="n + 100"
        houseCSS="house-dragons"
      />
      <!-- Unicorns  -->
      <HouseCard
        :options="options"
        v-for="n in options.unicornsCount"
        :key="n + 200"
        houseCSS="house-unicorns"
      />
      <!-- Centaurs  -->
      <HouseCard
        :options="options"
        v-for="n in options.centaursCount"
        :key="n + 300"
        houseCSS="house-centaurs"
      />
      <!-- Hydras  -->
      <HouseCard
        :options="options"
        v-for="n in options.hydrasCount"
        :key="n + 400"
        houseCSS="house-hydras"
      />
    </transition-group>
    <div class="fab-container">
      <div
        class="fab-btn neutral-btn custom-tooltip tooltip-left"
        @click="printPage"
      >
        <span class="tooltip-name">Print</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M22,8H17V5a1,1,0,0,0-.293-.707L13.72,1.306c0-.005-.006-.011-.01-.016a.867.867,0,0,0-.163-.1A.983.983,0,0,0,13,1H8A1,1,0,0,0,7,2V8H2A1,1,0,0,0,1,9V19a1,1,0,0,0,1,1H7v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V20h5a1,1,0,0,0,1-1V9A1,1,0,0,0,22,8ZM9,3h3V5a1,1,0,0,0,1,1h2V8H9Zm6,18H9V17h6Zm6-3H17V16a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v2H3V10H21Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import HousePointsOptions from "@/components/HousePoints/HousePointsOptions.vue";
import HouseCard from "@/components/HousePoints/HouseCard.vue";
import { ref } from "@vue/reactivity";

export default {
  components: { HousePointsOptions, HouseCard },
  setup() {
    const options = ref({
      dragonsCount: 1,
      unicornsCount: 1,
      centaursCount: 1,
      hydrasCount: 1,
      class: "Year 6",
    });

    function printPage() {
      window.print();
    }

    return { options, printPage };
  },
};
</script>

<style>
</style>
<template>

  <div class="page-a4">
    <EmployeeCard
      :employeesCards="employeesCards"
      v-if="employeesCards.length"
    />
    <div v-else class="empty-state">
      <img src="@/assets/img/card-empty-state.svg" />
      <p class="title">No cards</p>
      <p class="subtitle">
        Add a new card by pressing on the + button on the bottom right corner
      </p>
    </div>
    <div class="fab-container">
      <div
        class="fab-btn primary-btn custom-tooltip tooltip-left"
        @click="newCard"
      >
        <span class="tooltip-name">New Card</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M3,11h8V3a1,1,0,0,1,2,0v8h8a1,1,0,0,1,0,2H13v8a1,1,0,0,1-2,0V13H3a1,1,0,0,1,0-2Z"
          />
        </svg>
      </div>

      <div
        class="fab-btn neutral-btn custom-tooltip tooltip-left"
        @click="printPage"
      >
        <span class="tooltip-name">Print</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M22,8H17V5a1,1,0,0,0-.293-.707L13.72,1.306c0-.005-.006-.011-.01-.016a.867.867,0,0,0-.163-.1A.983.983,0,0,0,13,1H8A1,1,0,0,0,7,2V8H2A1,1,0,0,0,1,9V19a1,1,0,0,0,1,1H7v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V20h5a1,1,0,0,0,1-1V9A1,1,0,0,0,22,8ZM9,3h3V5a1,1,0,0,0,1,1h2V8H9Zm6,18H9V17h6Zm6-3H17V16a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v2H3V10H21Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import EmployeeCard from "@/components/EmployeeCards/EmployeeCard.vue";
import femaleAvatar from "@/assets/img/default-avatar.svg";
import { ref } from "@vue/reactivity";

export default {
  name: "EmployeeCardsPage",
  components: {
    EmployeeCard,
  },
  setup() {
    const employeesCards = ref([
      {
        id: 0,
        title: "Ms.",
        name: "Name",
        position: "Position",
        img: femaleAvatar,
      },
    ]);

    const counter = ref(1);

    const newCard = () => {
      let cardData = {
        id: counter.value++,
        title: "Ms.",
        name: "Name",
        position: "Position",
        img: femaleAvatar,
      };
      employeesCards.value.unshift(cardData);
      console.log(employeesCards.value);
    };

    const printPage = ()=>{
      window.print();
    }

    return { employeesCards, counter, newCard, printPage };
  },

 
};
</script>

<style  >
</style>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// You get this from the App page in the website
const firebaseConfig = {
    apiKey: "AIzaSyAiG3BfaDXhHX2zt_kBo1GBkjXx-yzO0KI",
    authDomain: "misb-3fb99.firebaseapp.com",
    projectId: "misb-3fb99",
    storageBucket: "misb-3fb99.appspot.com",
    messagingSenderId: "596880443094",
    appId: "1:596880443094:web:d1ca52e829c91dd29deb77",
    measurementId: "G-MXV3ZH90XY"
};



// init firebase 
firebase.initializeApp(firebaseConfig);


// init firestore service
const dbAuth =  firebase.auth();
const db = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;


export { dbAuth, db, timestamp }
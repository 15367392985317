<template>
  <!-- Student Name -->
  <div class="form-group">
    <label class="col-form-label"> {{ labelValue }}</label>
    <input
      class="input form-control"
      type="text"
      :placeholder="placeholderValue"
      v-model="searchTerm"
      v-on:keyup="resetSelectedItem"
    />

    <ul
      v-if="searchItems.length && !selectedItem"
      class="autocomplete-panel scrollbar"
    >
      <li
        v-for="item in searchItems"
        :key="item"
        @click="selectItem(item)"
        class="autocomplete-item"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from "vue";
export default {
  props: ["labelValue", "placeholderValue"],

  setup(props, context) {
    let searchTerm = ref("");
    let selectedItem = ref("");

    //NOTE 1: I used this formula to get the array form google sheet
    //=ArrayFormula(concatenate("[", join(", ", char(34)&B1:B144&char(34)), "]"))

    //NOTE 2: we were using  item.name instead of item
    // because the items array was full of objects and not only strings
    //Source: https://stevencotterill.com/articles/how-to-build-an-autocomplete-field-with-vue-3

    let items = [
      "Dragomir Alice",
      "Codrici Sofia-Maria",
      "Dumitrescu Maria",
      "Dahwa Ecaterina Amira",
      "Sebastian Howard Perry",
      "Cojocaru Toma Alexandru",
      "Cheikh Hussein Farid",
      "Nikoloz Konstantinidi ",

      "Stefan-Cristian Crusoveanu",
      "Florean-Henriques Alexandra",
      "Clara Allucia Phoenix Harris",
      "Lucia Allegra Morar",
      "Vasilescu Iris-Clara",
      "Sargious Luca-Kirolous",
      "Suru Georgia Alana",
      "Shloush Adam",
      "Katranas Apostolos-Panagiotis",
      "Ciorgan Ema Maria Olivia",
      "Nedelcov Tinca",
      "Velicu Amalia-Alexandra",

      "Ilie Celine-Nicolle-Izabela",
      "Kang Anna Hyehun",
      "Colan Matei Stefan",
      "Ema Jandova",
      "Vama Catrinel",
      "Almagor Rafael-Lavi",
      "Tihulca Matilda-Ilinca",
      "Stefanescu Philip-Iulian",
      "Lixandru Eva-Alexandra",
      "Petra Sofia Marin",
      "Galca Natalia",
      "Tudor Dinulescu Isabel Maria",
      "Simon Kovalenko",
      "Slonski Artem",
      "Faris Mohammed Al Asmari",
      "Sikorskiy Robert",
      "Azzo Dimitrie-Antonio",
      "Atay Denis",
      "Zhang Haochen",

      "Filip Janda",
      "Fatimah Ali Alhumaidi",
      "Chivaran Nikita Nicolae",
      "Misterian Medeea Sophia",
      "Vlad Zaraza-Maria",
      "Calin Emanuel-Sebastian",
      "Suru Iris Meira",
      "Iordachescu Danial",
      "Bai Haoran",
      "Victor Ioan Banica",
      "Mohora Corina",
      "Mohora Victor",
      "Covic Filip Alexandru",

      "Liam Noah Pinto",
      "Anastasia Konstantinidi",
      "Melicescu Ada Maria",
      "Pietrareanu Maria-Ioana",
      "Ilie Eduard Iulian",
      "Iakov Kovalenko",
      "Haider Ali-Askari",
      "Ivan Mark-Andrei",
      "Nguyen Minh Bao Ngoc",
      "Calin Maria-Sofia",
      "Edu Iris Elena",
      "Calina Ana-Teodora",
      "Stanciu Tudor",
      "Barbu Ania",
      "Maria Banica",
      "Slonska Diana",
      "Soucaliuc Ilie",

      "Filip Ceranic",
      "Tureanu Sonia Maria",
      "Sargious Ebram-Nectarie",
      "Barsoum Sam",
      "Mafakher Toranj",
      "Barbu Lucas",
      "Jugastreanu Eliza-Sofia",
      "Anas Eleithy Hamdy Abdel Hafez",
      "Teodor Gihun Kang",
      "Garjoaba Anastasia-Diana",
      "Siyana Georgieva Georgieva",

      "Barsoum Lin",
      "Yashika Ramesh",
      "Dobran Mihnea",
      "Tudor Visan",
      "Hancu Rares Andrei",
      "Tihulca Flavia-Mara",
      "Bilegt Tselmuun",
      "Saanvi Shahi",
      "Tonca-Strauff Ava",
      "Ana Cristina Durbaca Patranoiu",
      "Danika Singh",

      " Muhammad Aulia Arrasyid Prasetyo",
      "Zakhar Kovalenko",
      "Yoanna Yordanova Yordanova",
      "Vasilescu Victor Stefan",
      "Molla Marin",
      "Borgohain Abhiroop",
      "Miu Thea Alexandra",
      "Lixandru Bianca Maria",
      "Ivan Robin Alexandru",
      "Naidin Carla Maria",
      "Nutsa Koridze",
      "Boer Ian Constantin",
      "Tudor Dinulescu David Ioan",
      "Lev Yanovytskyi",
      "Mira Tanta",

      "Samantha Lopez",
      "Nguyen Duc Anh Tu",
      "Panazan Evelin-Natalia",
      "Costache Outas Nicolas",
      "Jugastreanu Clara Anastasia",
      "Oprea Oana Roberta",
      "Oancea Igor Andrei",
      "Dahwa Adam Alexander",
      "Vakhtang ( Vato ) Koridze",
      "Tristan Tanta",
      "De Andrade Ficher Luisa",
      "Kyra Singh",
    ];
    const searchItems = computed(() => {
      if (searchTerm.value === "") {
        return [];
      }
      let matches = 0;
      return items.filter((item) => {
        if (
          item.toLowerCase().includes(searchTerm.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++;
          return item;
        }
      });
    });
    const selectItem = (item) => {
      selectedItem.value = item;
      searchTerm.value = item;
    };
    const resetSelectedItem = () => {
      context.emit("emitInputValue", searchTerm);
      selectedItem.value = null;
    };

    return {
      items,
      searchTerm,
      searchItems,
      selectItem,
      selectedItem,
      resetSelectedItem,
    };
  },
};
</script>
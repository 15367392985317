<template>
  <div class="options-panel">
    <div class="header">Options</div>
    <div class="content">
      <!-- Class -->
      <div class="form-group">
        <label class="col-form-label"> Class</label>
        <select class="input form-control" v-model="options.class">
          <option value="Year 6">Year 6</option>
          <option value="Year 5">Year 5</option>
          <option value="Year 4">Year 4</option>
          <option value="Year 3">Year 3</option>
          <option value="Year 2">Year 2</option>
          <option value="Year 1">Year 1</option>
          <option value="Preschool">Preschool</option>
          <option value="Reception">Reception</option>
          <option value="Mini Group">Mini Group</option>
        </select>
      </div>

      <div class="cards-count">
        <!-- Dragons -->
        <div class="form-group">
          <label class="col-form-label">Dragons</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.dragonsCount > 0
                  ? options.dragonsCount--
                  : options.dragonsCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.dragonsCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="options.dragonsCount++"
            >
              +
            </button>
          </div>
        </div>

        <!-- Unicorns -->
        <div class="form-group">
          <label class="col-form-label">Unicorns</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.unicornsCount > 0
                  ? options.unicornsCount--
                  : options.unicornsCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.unicornsCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="options.unicornsCount++"
            >
              +
            </button>
          </div>
        </div>

        <!-- Centaurs -->
        <div class="form-group">
          <label class="col-form-label">Centaurs</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.centaursCount > 0
                  ? options.centaursCount--
                  : options.centaursCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.centaursCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="options.centaursCount++"
            >
              +
            </button>
          </div>
        </div>

        <!-- Hydras -->
        <div class="form-group">
          <label class="col-form-label">Hydras</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.hydrasCount > 0
                  ? options.hydrasCount--
                  : options.hydrasCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.hydrasCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="options.hydrasCount++"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["options"],
};
</script>

<style>
</style>
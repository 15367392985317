<template>
  <div class="options-panel">
    <div class="header">Options</div>
    <div class="content">
      <!-- Student Name -->
      <AutoCompleteInput
        @emitInputValue="handleInputValue"
        labelValue="Student Name"
        placeholderValue="Ex: John Smith"
      />

      <!-- Class -->
      <div class="form-group">
        <label class="col-form-label"> Class</label>
        <select class="input form-control" v-model="options.studentClass">
          <option value="Year 6">Year 6</option>
          <option value="Year 5">Year 5</option>
          <option value="Year 4">Year 4</option>
          <option value="Year 3">Year 3</option>
          <option value="Year 2">Year 2</option>
          <option value="Year 1">Year 1</option>
          <option value="Preschool">Preschool</option>
          <option value="Reception">Reception</option>
          <option value="Mini Group">Mini Group</option>
          <option value="">No Class</option>
        </select>
      </div>

      <div class="subjects-count">
        <!-- Blank -->
        <div class="form-group">
          <label class="col-form-label">Blank</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.blankCount > 0 ? options.blankCount-- : options.blankCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.blankCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.blankCount < 10 ? options.blankCount++ : options.blankCount
              "
            >
              +
            </button>
          </div>
        </div>
        <!-- Math -->
        <div class="form-group">
          <label class="col-form-label">Math</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.mathCount > 0 ? options.mathCount-- : options.mathCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.mathCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.mathCount < 10 ? options.mathCount++ : options.mathCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- History -->
        <div class="form-group">
          <label class="col-form-label">History</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.historyCount > 0
                  ? options.historyCount--
                  : options.historyCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.historyCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.historyCount < 10
                  ? options.historyCount++
                  : options.historyCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- English -->
        <div class="form-group">
          <label class="col-form-label">English</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.englishCount > 0
                  ? options.englishCount--
                  : options.englishCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.englishCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.englishCount < 10
                  ? options.englishCount++
                  : options.englishCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Geography -->
        <div class="form-group">
          <label class="col-form-label">Geography</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.geographyCount > 0
                  ? options.geographyCount--
                  : options.geographyCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.geographyCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.geographyCount < 10
                  ? options.geographyCount++
                  : options.geographyCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Science -->
        <div class="form-group">
          <label class="col-form-label">Science</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.scienceCount > 0
                  ? options.scienceCount--
                  : options.scienceCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.scienceCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.scienceCount < 10
                  ? options.scienceCount++
                  : options.scienceCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- P.S.H.E. -->
        <div class="form-group">
          <label class="col-form-label">P.S.H.E.</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.psheCount > 0 ? options.psheCount-- : options.psheCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.psheCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.psheCount < 10 ? options.psheCount++ : options.psheCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Topic -->
        <div class="form-group">
          <label class="col-form-label">Topic</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.topicCount > 0
                  ? options.topicCount--
                  : options.topicCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.topicCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.topicCount < 10
                  ? options.topicCount++
                  : options.topicCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Spelling  -->
        <div class="form-group">
          <label class="col-form-label">Spelling</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.spellingCount > 0
                  ? options.spellingCount--
                  : options.spellingCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.spellingCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.spellingCount < 10
                  ? options.spellingCount++
                  : options.spellingCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Journal -->
        <div class="form-group">
          <label class="col-form-label">Journal</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.journalCount > 0
                  ? options.journalCount--
                  : options.journalCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.journalCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.journalCount < 10
                  ? options.journalCount++
                  : options.journalCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- German -->
        <div class="form-group">
          <label class="col-form-label">German</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.germanCount > 0
                  ? options.germanCount--
                  : options.germanCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.germanCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.germanCount < 10
                  ? options.germanCount++
                  : options.germanCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Romanian -->
        <div class="form-group">
          <label class="col-form-label">Romanian</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.romanianCount > 0
                  ? options.romanianCount--
                  : options.romanianCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.romanianCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="
                options.romanianCount < 10
                  ? options.romanianCount++
                  : options.romanianCount
              "
            >
              +
            </button>
          </div>
        </div>

        <!-- Default -->
        <div class="form-group">
          <label class="col-form-label">Default</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.defaultCount > 0
                  ? options.defaultCount--
                  : options.defaultCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.defaultCount"
            />
            <button class="plus-btn neutral-btn" @click="options.defaultCount++">
              +
            </button>
          </div>
        </div>

        <!-- Christmas -->
        <div class="form-group">
          <label class="col-form-label">Christmas</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.christmasCount > 0
                  ? options.christmasCount--
                  : options.christmasCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.christmasCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="options.christmasCount++"
            >
              +
            </button>
          </div>
        </div>

        <!-- Whitboard -->
        <div class="form-group">
          <label class="col-form-label">Whiteboard</label>
          <div class="counter-wrapper">
            <button
              class="minus-btn neutral-btn"
              @click="
                options.whiteboardCount > 0
                  ? options.whiteboardCount--
                  : options.whiteboardCount
              "
            >
              -
            </button>
            <input
              class="input form-control"
              type="number"
              min="0"
              max="10"
              v-model="options.whiteboardCount"
            />
            <button
              class="plus-btn neutral-btn"
              @click="options.whiteboardCount++"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onUpdated } from "@vue/runtime-core";
import AutoCompleteInput from "../AutoCompleteInput.vue";

export default {
  components: { AutoCompleteInput },
  props: ["options"],

  setup(props) {
    const handleInputValue = (searchTerm) => {
      props.options.studentName = searchTerm;
    };

    onUpdated(() => {
      if (props.options.mathCount > 10) {
        props.options.mathCount = 10;
      }
      if (props.options.historyCount > 10) {
        props.options.historyCount = 10;
      }
      if (props.options.englishCount > 10) {
        props.options.englishCount = 10;
      }
      if (props.options.geographyCount > 10) {
        props.options.geographyCount = 10;
      }
      if (props.options.scienceCount > 10) {
        props.options.scienceCount = 10;
      }
      if (props.options.psheCount > 10) {
        props.options.psheCount = 10;
      }
      if (props.options.topicCount > 10) {
        props.options.topicCount = 10;
      }
      if (props.options.journalCount > 10) {
        props.options.journalCount = 10;
      }
      if (props.options.germanCount > 10) {
        props.options.germanCount = 10;
      }
      if (props.options.romanianCount > 10) {
        props.options.romanianCount = 10;
      }
      // if (props.options.defaultCount > 10) {
      //   props.options.defaultCount = 10;
      // }
    });

    return { handleInputValue };
  },
};
</script>

<style>
</style>